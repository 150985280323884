import { Controller } from "stimulus";

export default class extends Controller {
  click(event) {
    // Allow propagation for close modal buttons.
    if (event.target.classList.value === 'modal__close') {
      if (this.data.get('refresh') == 'true') {
        Turbo.visit(window.location.toString(), { action: 'replace' })
      }

      return;
    }

    event.stopPropagation();
  }

  blur(event) {
    if (this.data.get('refresh') == 'true') {
      Turbo.visit(window.location.toString(), { action: 'replace' })
    }

    Array.from(document.getElementsByClassName('micromodal-slide')).forEach(function(element) {
      try {
        window.Tefter.MicroModal.close(element['id']);
      } catch(error) {}
    });

    // Reset sidebar css opacity
    $('.sidebar').css('opacity', 1);
  }
}
