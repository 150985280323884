import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['scan']

  onScanSuccess(event) {
    let [data, status, xhr] = event.detail;
  }

  connect() {
    this.scanProcess = false;
  }


  scanClick(event) {
    this.scanTarget.addEventListener('ajax:success', this.handleSuccess.bind(this))
    this.scanTarget.addEventListener('ajax:error', this.handleError.bind(this))

    if (!this.scanProcess) {
    }
  }

  handleSuccess(event) {
  }

  handleError(event) {
  }
}
