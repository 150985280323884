import { Controller } from "stimulus";
import tippy from 'tippy.js';
import { defaultTippyOptions } from '../packs/utils.js';

export default class extends Controller {
  initialize() {
    // Initialize hidden toggler
    this._initial_state = this.data.get('hidden') == 'true' ? true : false;
    this._hidden = this._initial_state;

    // Initialize id
    this._id = this.data.get('listId');

    this._hiddenBookmarks = Number(this.data.get('hiddenBookmarks'));

    this._visibleBookmarks = Number(this.data.get('visibleBookmarks'));

    this._sharedBookmarks = Number(this.data.get('sharedBookmarks'));

    this._subscriptionsCount = Number(this.data.get('subscriptionsCount'));
  }

  toggle(event) {
    event.stopPropagation();

    // Reverse hidden value every time a toggler is pressed.
    this._hidden = !this._hidden;

    if (this._hidden == this._initial_state) {
      return;
    }

    // If this is a new list do not bother on checking
    // for bookmarks with non compatible visibilities.
    if (this._id === '') {
      return;
    }

    let publicBookmarks = this._visibleBookmarks + this._sharedBookmarks;
    let privateBookmarks = this._hiddenBookmarks;

    if (this._hidden === true && (publicBookmarks > 0 || this._subscriptionsCount > 0)) {
      this.tippy = this.element._tippy || tippy(this.element , {
        ...defaultTippyOptions(),
        trigger: 'manual',
        distance: 25,
        placement: 'bottom',
        onHide(instance) {
          if (instance.state.isShown == false) {
            return false;
          }
        }
      });

      let content = `Please note that ${publicBookmarks} public bookmarks of this list
will change to private ⚠`;

      if (this._subscriptionsCount > 0) {
        content += ` and ${this._subscriptionsCount} list subscription(s) will be dropped!`
      }

      this.tippy.setContent(content);
      this.tippy.show();

      return;
    }

    if (this._hidden == false && privateBookmarks > 0) {
      this.tippy = this.element._tippy || tippy(this.element , {
        ...defaultTippyOptions(),
        trigger: 'manual',
        distance: 25,
        placement: 'bottom',
        onHide(instance) {
          if (instance.state.isShown == false) {
            return false;
          }
        }
      });

      let content = `Please note that ${privateBookmarks} private bookmarks of
this list will change to public ⚠` ;

      this.tippy.setContent(content);
      this.tippy.show();

      return;
    }
  }
}
