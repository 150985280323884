import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['number', 'cvc', 'month', 'year'];

  numberKeydown(event) {
    if (event.keyCode === 8) {

      if ((this.numberTarget.value.length - 1) % 5 == 0) {
        this.numberTarget.value = this.numberTarget.value.slice(0, -2);
        event.stopPropagation();
        event.preventDefault();
        return
      }
    }
  }

  numberKeypress(event) {
    if (isNaN(parseInt(event.key)) || this.numbersLength(this.numberTarget.value) > 15) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    if (((this.numbersLength(this.numberTarget.value)) % 4 === 0) &&
        (this.numbersLength(this.numberTarget.value) != 0)) {
      this.numberTarget.value += ' ';
    }
  }

  numbersLength(value) {
    let chars = value.split('');
    return chars.filter(number => number != ' ').length
  }

  numberChange(event) {
    let chars = this.numberTarget.value.split('');
    chars = chars.filter(number => !isNaN(parseInt(number)));
    let newValue = '';

    for (let i = 0; i < chars.length; i++) {
      if (i % 4 == 0 && i != 0) {
        newValue += ' ';
      }

      newValue += chars[i];
    }

    newValue = newValue.slice(0, 19);

    this.numberTarget.value = newValue;
  }

  cvcKeypress(event) {
    if (isNaN(parseInt(event.key)) || this.cvcTarget.value.length > 2) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  }

  monthKeypress(event) {
    if (isNaN(parseInt(event.key)) || this.monthTarget.value.length > 1) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  }

  yearKeypress(event) {
    if (isNaN(parseInt(event.key)) || this.yearTarget.value.length > 3) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  }
}
