import { Controller } from "stimulus";
import { prepareModal, deleteBookmarkCard, ajaxPost, defaultTippyOptions,
         tippyWithDynamicContent, hideAllBookmarksTippy, showModal } from '../packs/utils.js';

/* Responsible to handle:
 * - menu behavior in golink cards
 * - form behavior in golink new/edit
 * - toggling in orgs modals
 *
 * jQuery: Free
 */
export default class extends Controller {
  static targets = ['haveit', 'url', 'name'];

  showActions(event) {
    const element = event.srcElement;
    let golinkId = element.dataset.golinkId;
    const organizationId = element.dataset.organizationId;
    let ellipsisButton = this.element;
    const path = '/aliases/' + golinkId + '/actions?&organization_id=' + organizationId;

    tippyWithDynamicContent(event, path, {}, {
      ...defaultTippyOptions(),
      distance: 8,
      offset: '-26, 5',
      touch: true,
      delay: [20, 100],
      duration: [10, 10],
      placement: 'top',
      hideOnClick: true,
      onShow(instance) {
        instance.set({trigger: 'click'});

        ellipsisButton.style.opacity = 1;
      },

      onHide(instance) {
        instance.set({trigger: 'click'})

        ellipsisButton.style.opacity = '';
      }
    });
  }

  delete(event) {
    deleteBookmarkCard(event.srcElement);
  }

  showSuggestions(event) {
    const orgId = event.srcElement.getAttribute('data-organization-id') ||
          event.srcElement.parentElement.getAttribute('data-organization-id');

    let path = `/aliases/suggestions`;

    if (orgId != '' && orgId != null) {
      path = `/orgs/${orgId}` + path;
    }

    let $document = $('body');
    const modalId = 'aliases-suggestions';

    // Hide all other tippy elements.
    hideAllBookmarksTippy();

    // Clear Turbolinks cache
    Turbo.clearCache();

    $.ajax({
      url: path
    }).done(function (result) {
      $document.append(result);

      showModal(modalId);
    });
  }

  addToOrg(event) {
    prepareModal(event, 'aliases', 'org_modal', 'org-modal');
  }

  toggleGolinkToOrg(event) {
    const golinkId = this.element.dataset.golinkId;
    const orgId = event.srcElement.getAttribute('id');
    const isChecked = event.srcElement.checked;
    const data = { organization_id: orgId, id: golinkId };
    let path = [`/aliases/${golinkId}/`];

    if (orgId != '' && orgId != null) {
      path.unshift(`/orgs/${orgId}`);
    }

    path.push( isChecked ? 'add_to_org' : 'remove_from_org' );

    ajaxPost(path.join(''), data);
  }

  toggleSuggestion(event) {
    const golinkName = this.element.getAttribute('data-golink-name');
    const golinkUrl = this.element.getAttribute('data-golink-url');
    const golinkDescription = this.element.getAttribute('data-golink-description');
    const orgId = event.srcElement.getAttribute('data-organization-id');
    const isChecked = $(event.srcElement).is(':checked');
    let path = `/aliases/`;

    if (orgId != '' && orgId != null) {
      path = `/orgs/${orgId}` + path;
    }

    if (isChecked === true) {
      path += 'add_suggestion';
    }
    else {
      path += 'remove_suggestion';
    }

    $.ajax({
      url: path,
      method: 'POST',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: { organization_id: orgId,
              name: golinkName,
            description: golinkDescription,
            url: golinkUrl }
    }).done(function (result) {
    });
  }
}
