import { debounce } from './vendor/debounce.js'

export default class Endless {
  attach(options) {
    this.THRESHOLD = 300;
    this.debounce_time = 200;

    const path = options.path;

    this.paginationUrl = path + '/pagination';

    this.isPaginating = false;
    this.currentPage = 1;
    this.older = 0;
    this.$paginationElem = $('.pagination');
    this.totalPages = parseInt(this.$paginationElem.attr('data-pagination-pages'));

    this.$paginationElem.hide();
    this.bindHandlers();
  }

  bindHandlers() {
    $(window).off('scroll');
    $(window).on('scroll', debounce(function () {
      this.handleScroll();
    }.bind(this), this.debounce_time));
  }

  paginationEndpoint(pageNumber) {
    return `${this.paginationUrl}${this.historyEndpoint(pageNumber)}`;
  }

  historyEndpoint(pageNumber) {
    // For browser support see here https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#Browser_compatibility
    let queryParams = `?page=${pageNumber}`;

    return queryParams;
  }

  $users() { return $('#resources'); }

  handleScroll() {
    const $window = $(window);
    const $document = $(document);

    if (!this.isPaginating && this.currentPage < this.totalPages && $window.scrollTop() >
        $document.height() - $window.height() - this.THRESHOLD) {
      this.isPaginating = true;
      this.currentPage++;

      this.$paginationElem.show();

      $.ajax({
        url: this.paginationEndpoint(this.currentPage)
      }).done(function (result) {
        var newIds = $.makeArray($(result).filter('.card').map(function(i, el) {
          return $(el).data('id')
        }));

        this.$users().append(result);
        this.isPaginating = false;
      }.bind(this));
    }

    if (this.currentPage >= this.totalPages) {
      this.$paginationElem.hide();
    }
  }
}
