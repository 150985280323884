import { Controller } from 'stimulus'
import Timeline from '../packs/timeline.js'

export default class extends Controller {
  connect() {
    (new Timeline).attach( { userName: this.data.get('username') });
  }

  disconnect() {
    $(window).off('scroll');
  }
}
