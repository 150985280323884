import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const $element = this.element;
  }

  toggle(event) {
    event.preventDefault();

    let guide = document.querySelector('.usage-guide');

    if (guide) {
      guide.classList.toggle('hidden');
    }
  }
}
