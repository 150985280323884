import { Controller } from 'stimulus'
import Endless from '../packs/endless.js'

export default class extends Controller {
  connect() {
    (new Endless).attach( { path: this.data.get('username') });
  }

  disconnect() {
    $(window).off('scroll');
  }
}
