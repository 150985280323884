export default class TrialNotice {
  attach() {
    this.$el = $('.trial-notice');
    this.bindHandlers();
  }

  bindHandlers() {
    this.$el.find('.subscription.btn').on('click', this.clickSubscription.bind(this));
  }

  clickSubscription(e) {
    e.preventDefault();
    this.$el.find('.pricing-plans-section').show();
  }
}
