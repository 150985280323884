import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['clear'];

  initialize() {
    let visibleTags = $('.search-tag-selected').length;

    if (!this.hasClearTarget) {
      return;
    }

    let $clearFilters = $(this.clearTarget);

    if (visibleTags > 3) {
      $clearFilters.removeClass('hidden');
    }
    else {
      $clearFilters.addClass('hidden');
    }
  }
}
