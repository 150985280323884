import { Controller } from "stimulus";
import tippy from 'tippy.js';
import { tokenize, words, webShare, checkAuthentication } from '../packs/utils.js';

export default class extends Controller {
  static targets = ['subscriptionsCount', 'share'];

  connect() {
    if (this.hasShareTarget == true) {
      if (!navigator.share) {
        this.shareTarget.parentElement.style.display = 'none';
      }
    }
  }

  toggleSubscribe(event) {
    const path = event.detail[0].path;
    const counter = event.detail[0].counter;
    let caption = event.srcElement.text;

    caption = (caption === 'Subscribe') ? 'Unsubscribe' : 'Subscribe';

    // Toggle caption
    event.srcElement.text = caption;

    // Change post path
    event.srcElement.href = path;

    // Change background colors
    event.srcElement.classList.toggle('!bg-emerald-400');
    event.srcElement.classList.toggle('hover:!bg-emerald-500');

    // Update list subscription counter
    this.subscriptionsCountTarget.setAttribute('data_src', counter);
  }

  share(event) {
    webShare(event);
  }

  delete(event) {
    $(event.currentTarget).parents('.card').remove();
    this.decrementCount(document.getElementById('lists-count'));
  }

  deleteSubscription(event) {
    $(event.currentTarget).parents('.card').remove();
    this.decrementCount(document.getElementById('list-subscriptions-count'));
  }

  beforeSend(event) {
    checkAuthentication();
  }

  addSubscription(event) {
    event.srcElement.classList.toggle('hidden');
    this.increamentCount(document.getElementById('list-subscriptions-count'));
  }

  decrementCount($el) {
    const count = parseInt($el.innerHTML.replace('(', '').replace(')', ''), 10);

    $el.innerHTML = `(${count - 1})`;
  }

  increamentCount($el) {
    const count = parseInt($el.innerHTML.replace('(', '').replace(')', ''), 10);

    $el.innerHTML = `(${count + 1})`;
  }

  filter(event) {
    let query = event.srcElement.value;
    let regex = new RegExp(`^${query}`, 'giu');
    let lists;
    let selectedLists = [];
    let unselectedLists = [];
    let listNode;

    lists = event.srcElement.parentElement.querySelectorAll('label.form-checkbox');
    listNode = event.srcElement.parentElement.querySelector('ul');

    lists.forEach(list => {
      if (list.children[0].checked && query.length == 0) {
        selectedLists.push(list.parentElement);
      } else {
        unselectedLists.push(list.parentElement);
      }

      listNode.removeChild(list.parentElement);
    });

    selectedLists = selectedLists.sort((a, b) =>
      a.children[0].textContent.trim().localeCompare(
        b.children[0].textContent.trim()));


    selectedLists.forEach(list => {
      let label = list.children[0];
      label.classList.remove('hidden');

      listNode.appendChild(list);
    });

    unselectedLists = unselectedLists.sort((a, b) =>
      a.children[0].textContent.trim().localeCompare(
        b.children[0].textContent.trim()));


    unselectedLists.forEach(list => {
      let label = list.children[0];
      let name = label.textContent.trim();
      let tokens = words([name]);

      if (tokens.some(word => regex.test(word))) {
        label.classList.remove('hidden');
      } else {
        label.classList.add('hidden');
      }

      listNode.appendChild(list);
    });
  }
}
