import { Controller } from "stimulus";
import tippy from 'tippy.js';
import { defaultTippyOptions } from '../packs/utils.js';

export default class extends Controller {
  static targets = ['link'];

  handleResponse(event) {
    const $this = $(event.currentTarget);
    const response = event.detail[0];

    if (response && response.status == 'maximum_feeds') {
       this.tippy = this.element._tippy || tippy(this.element, {
         ...defaultTippyOptions(),
         distance: 25,
         placement: 'bottom'
       });

      let content = `You have reached the maximum number of feeds/users that your plan
supports. Please <strong>upgrade</strong> your account or contact as at support@tefter.io.`

       this.tippy.setContent(content);
       this.tippy.show();
    }
    else if (response) {
      this.toggleButton();
      this.updateForm(response);
    }
  }

  beforeSend(event) {
    if (Tefter.user_signed_in === false) {
      event.stopPropagation();
      event.preventDefault();

      window.location = window.Tefter.signInPath;
    }
  }

  updateForm(response) {
    this.linkTarget.href = response.url;
    this.linkTarget.setAttribute('data-method', response.method);
  }

  toggleButton() {
    let subscribeLabel = 'Follow';
    let subscribingLabel = 'Following';

    if ($(this.linkTarget).parent().parent().find('h2.feed').length > 0) {
      subscribeLabel = 'Subscribe';
      subscribingLabel = 'Subscribed';
    }

    const $button = $(this.linkTarget);

    if ($button.hasClass('btn-follow')) {
      $button.prop('value', subscribingLabel);
      $button.text('✓ ' + subscribingLabel);
    } else {
      $button.prop('value', subscribeLabel);
      $button.text('+ ' + subscribeLabel);
    }

    $button.toggleClass('btn-unfollow');
    $button.toggleClass('btn-follow');
  }
}
