import { Controller } from "stimulus";
import { prepareModal, deleteBookmarkCard, ajaxPost, defaultTippyOptions,
         tippyWithDynamicContent, hideAllBookmarksTippy, showModal } from '../packs/utils.js';

/* Responsible to handle:
 * - menu behavior in golink cards
 * - form behavior in golink new/edit
 * - toggling in orgs modals
 *
 * jQuery: Free
 */
export default class extends Controller {
  static targets = ['haveit', 'url', 'name'];

  connect() {
    if (this.hasUrlTarget) {
      this.urlTarget.value = this.urlTarget.value ||
        localStorage.getItem('golinkUrl');

      if (this.urlTarget.value != localStorage.getItem('golinkUrl')) {
        localStorage.setItem('golinkUrl', this.urlTarget.value);
      }
    }

    if (this.hasNameTarget) {
      this.nameTarget.value = this.nameTarget.value ||
        localStorage.getItem('golinkName');

      if (this.nameTarget.value != localStorage.getItem('golinkName')) {
        localStorage.setItem('golinkName', this.nameTarget.value);
      }
    }
  }

  changeName(event) {
    const name = event.srcElement.value;
    const haveItElement = this.haveitTarget;
    const currentName = this.data.get('name');
    const organizationId = this.data.get('organization-id');

    if (name == currentName || event.key == "Enter") {
      return;
    }

    ajaxPost('/aliases/haveit',
             { name: name, organization_id: organizationId }, function(response) {
               if (response.golink) {
                 haveItElement.classList.remove('hidden');
                 const linkElement = haveItElement.querySelector('.edit-link');
                 linkElement.href = linkElement.href.replace(':id', response.golink.id);
               } else {
                 haveItElement.classList.add('hidden');
               }
             });
  }

  blurUrl(event) {
    localStorage.setItem('golinkUrl', event.target.value);
  }

  blurName(event) {
    localStorage.setItem('golinkName', event.target.value);
  }

  submitForm(event) {
    localStorage.setItem('golinkUrl', '');
    localStorage.setItem('golinkName', '');
  }

  resetStorage(event) {
    localStorage.setItem('golinkUrl', '');
    localStorage.setItem('golinkName', '');
  }
}
