import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['input', 'message', 'button'];

  reset() {
    let $modal = $("#modal-new-feed");

    let title = 'Add new rss feed';

    if (window.Tefter.orgName) {
      title = `${title} to ${window.Tefter.orgName}`;
    }

    $("#modal-new-feed-title").text(title);

    $modal.find('input[type=text]').show().val('');
    $modal.find('input[type=text]').val('');

    setTimeout(function() { $modal.find('input[type=text]').focus(); }, 100);

    $modal.find('.message').text('');
    $modal.find('.create').show().val('Follow')
  }

  handleResponse(event) {
    let [data, status, xhr] = event.detail;
    let message = '';

    if (data.status === 422) {
      if (data.responseText.match('Your plan supports')) {
        message = JSON.parse(data.responseText).errors.title;
      }
      else {
        message = `RSS feed is invalid. Please provide a well
formatted rss feed like https://www.example.com/rss`
      }

      this.messageTarget.innerHTML = message;
    }

    if (data.status === 200) {
      let response = JSON.parse(data.response);

      if (response.existing == true) {
        $(this.buttonTarget).hide();

        let url = '/' + response.object.username;

        message = `You already followed ${response.object.username}. Click <a href="${url}">here</a> to see feed's page.`;

        this.messageTarget.innerHTML = message;
      }
    }

    if (data.status === 201) {
      let response = JSON.parse(data.response);
      let ctrl = this;

      let modalId = ctrl.element.id.replace('-content', '');

      $(ctrl.inputTarget).hide();
      $(ctrl.buttonTarget).hide();
      $("#modal-new-feed-title").text('You followed one more feed!');

      let refreshUrl = '/following';

      window.setTimeout(window.Tefter.MicroModal.close, 2 * 1000, modalId);
      window.setTimeout(Turbo.visit, 1000, refreshUrl, { action: 'replace' });

      ctrl.messageTarget.innerHTML = message;
    }
  }

  submit(event) {
    this.buttonTarget.value = 'Following..';
  }
}
