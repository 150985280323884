import { Controller } from "stimulus";
import tippy from 'tippy.js';
import { defaultTippyOptions } from '../packs/utils.js';

export default class extends Controller {
  initialize() {
    this._initialState = this.data.get('privacy') == 'true' ? true : false;
    this._privacy = this._initialState;

    this._followers = Number(this.data.get('followers'));
  }

  toggle(event) {
    this._privacy = !this._privacy;

    if (this._privacy == this._initialState) {
      return;
    }

    if (this._privacy == true && this._followers > 0) {
      this.tippy = event.srcElement._tippy || tippy(event.srcElement , {
        ...defaultTippyOptions(),
        trigger: 'manual',
        distance: 15,
        placement: 'bottom-start',
        onHide(instance) {
          if (instance.state.isShown == false) {
            return false;
          }
        }
      });

      let content = `Please note that your ${this._followers} followers
will automatically stop following your account  ⚠`;

      this.tippy.setContent(content);
      this.tippy.show();

      return;
    }
  }
}
