import MicroModal from 'micromodal';
import tippy from 'tippy.js';

export function closeModal(modalId) {
  MicroModal.close(modalId);
}

export function showModal(modalId) {
  let bodyClass = '.dashboard';

  modalId = modalId || event.srcElement.getAttribute('data-modal-id');

  if (modalId == "") {
    event.stopPropagaton();

    return;
  }

  if (modalId == null) {
    return;
  }

  MicroModal.show(modalId, {
    debugMode: false,
    disableScroll: false,
    onShow: function (modal) { document.querySelector(bodyClass).classList.add(modal.id) },
    onClose: function (modal) { document.querySelector(bodyClass).classList.remove(modal.id) },
    closeTrigger: 'data-custom-close',
    awaitCloseAnimation: true
  })
}

export function hideAllBookmarksTippy() {
  Array.from(document.querySelectorAll('.tippy-hide-component')).forEach(function(el) {
    if (el._tippy) {
      el._tippy.hide();
    }
  });
}

export function destroyAllBookmarksTippy() {
  Array.from(document.querySelectorAll('.js-burger-button')).forEach(function(el) {
    if (el._tippy) {
      el._tippy.destroy();
    }
  });
}

/* Given an array of strings, it returns an array of uniq words.
 *
 * @param array [Array<String>]
 * @param minWordsLength [Integer] ignore words smaller than this threshold
 * @return [Array<String>] an array of words
 */
export function words(array, minWordsLength = 1) {
  return uniq(array.
              map(sentence => tokenize(sentence)).
              flat().
              filter(word => word.length > minWordsLength));
}

/* Tokenizes a valid string into words.
 *
 * @param string [String] the string
 * @return [Array<String>] an array of words
 */
export function tokenize(string) {
  if (string === undefined || string == null || typeof(string) != 'string') {
    return [];
  }

  return string.split(/\W+/)
}

export function uniq(array) {
  return array.filter((v, i, a) => a.indexOf(v) === i);
}

export function uniqBy(array, field) {
  let hash = {};

  array.forEach((element) => {
    const key = element[field];

    if (!hash[key]) {
      hash[key] = element;
    }
  });

  return Object.values(hash);
}

export function prepareModal(event, controller = 'bookmarks', action, modalName) {
  const id = event.srcElement.getAttribute('data-id');
  const orgId = event.srcElement.getAttribute('data-organization-id');
  let path = `/${controller}/${id}/${action}`;

  if (orgId != '') {
    path = `/orgs/${orgId}` + path;
  }

  let $document = $('body');
  const modalId = `${modalName}-${id}`;

  // Hide all other tippy elements.
  hideAllBookmarksTippy();

  // Clear Turbolinks cache
  Turbo.clearCache();

  $.ajax({
    url: path
  }).done(function (result) {
    $document.append(result);

    showModal(modalId);
  });
}

function decrementCount($el) {
  const count = parseInt($el.text().replace('(', '').replace(')', ''), 10);

  $el.text(`(${count - 1})`);
}

// Removes bookmark card, tippy elements and decrements counter
export function deleteBookmarkCard(element) {
  let id = element.getAttribute('data-id');
  const $element = $("li[data-id='" + id + "']")[0];

  destroyAllBookmarksTippy();

  if ($element) {
    $element.remove();
  }

  decrementCount($('.resources-count'));
}

function ajax(path, method, data, params, doneFn = function(result) {},
              thenFn = function(result) {}) {
  $.ajax({
    url: path,
    method: method,
    params: params,
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    data: data }).
    done(doneFn).
    then(thenFn);
}

export function ajaxPost(path, data, fn = function(result) {}) {
  ajax(path, 'POST', data, {}, fn);
}

export function ajaxDelete(path, data, fn = function(result) {}) {
  ajax(path, 'DELETE', data, {}, fn);
}

export function ajaxGet(path, params, fn) {
  $.ajax({
    url: path,
    params: params,
    type: 'get'
  }).
    then(fn);
}

export function ajaxPut(path, data, fn = function(result) {}) {
  $.ajax({
    url: path,
    type: 'put',
    data: data }).
    then(fn);
}

export function defaultTippyOptions() {
  return {
    interactive: true,
    trigger: 'click',
    theme: 'light',
    arrow: 'true',
    allowHTML: true
  }
}

// Stops event propagation and redirects to users login page if a non logged in
// user tries to access this event.
export function checkAuthentication(event) {
  if (Tefter.user_signed_in === false) {
    event.stopPropagation();
    event.preventDefault();

    window.location = window.Tefter.signInPath;

    return;
  }
}

export function tippyWithDynamicContent(event, path, params, tippyOptions,
options = { hideAllTippys: true, ignoreEventType: "mouseenter" }) {
  event.preventDefault();

  if (options.ignoreEventType == event.type) {
    return;
  }

  let tippyVisible = false;

  if (event.srcElement._tippy) {
    tippyVisible = event.srcElement._tippy.state.isVisible;
  }

  // Hide all other tippy elements.
  if (options.hideAllTippys == true) {
    hideAllBookmarksTippy();
  }

  // Do not try to re-render tippy.
  if (tippyVisible == true) {
    return;
  }

  const hasTippy = event.srcElement._tippy;
  let tippyComponent;

  tippyComponent = event.srcElement._tippy || tippy(event.srcElement, tippyOptions);

  // Load content only when _tippy is undefined
  if (hasTippy == undefined) {
    ajaxGet(path, params, function(response) {
      tippyComponent.setContent(response);
    })
    }

  tippyComponent.show();
}

export function webShare(event) {
  const shareData = {
    title: event.target.dataset.title,
    text: event.target.dataset.text,
    url: event.target.dataset.url
  };

  navigator.share(shareData).then(() => {}).catch();
}
