import { Controller } from 'stimulus';
import tippy from 'tippy.js';
import { defaultTippyOptions } from '../packs/utils.js';

export default class extends Controller {
  optionsTemplate(path, pred, options, urlParams, separator = '') {
    return options.map((option) => {
      return `<li class="${pred == option.key ? 'selected' : ''}">
           <a href="${path + '=' + option.key + urlParams}">
             ${option.label}
           </a>
         </li>`
    }).join(separator)
  }

  renderViewMode(currentView, urlParams) {
    let options = [ { key: 'default', label: 'default' },
                    { key: 'condensed', label: 'compact' }];

    return `
     <h4>View</h4>
       <ul class="view-choices">
         ${this.optionsTemplate('?view_mode', currentView, options, urlParams)}
       </ul>`
  }

  renderSizeMode(currentSize, urlParams) {
    let options = [ { key: '25', label: '25' },
                    { key: '50', label: '50' },
                    { key: '100', label: '100' } ];

    return `
     <h4>Page size</h4>
       <ul class="size-choices">
        ${this.optionsTemplate('?size', currentSize, options, urlParams, ' | ')}
       </ul>
    `
  }

  listsContent(currentView, currentOrder, currentSize, urlParams) {
    let options = [ { key: 'newest-created', label: 'newest' },
                    { key: 'oldest-created', label: 'oldest' },
                    { key: 'alphabetical-asc', label: 'alphabetical' }];

    return `
     <div class="view-choices-wrapper">
       ${this.renderViewMode(currentView, urlParams)}

       <h4>Order</h4>

       <ul class="view-choices">
         ${this.optionsTemplate('?sort', currentOrder, options, urlParams)}
       </ul>

       ${this.renderSizeMode(currentSize, urlParams)}
     </div>
        `
  }

  goLinksContent(currentView, currentOrder, currentSize, urlParams) {
    let options = [ { key: 'most-used', label: 'most used' },
                    { key: 'newest-created', label: 'newest' },
                    { key: 'oldest-created', label: 'oldest' },
                    { key: 'alphabetical-asc', label: 'alphabetical' }];

    return `
     <div class="view-choices-wrapper">
       ${this.renderViewMode(currentView, urlParams)}

       <h4>Order</h4>
         <ul class="view-choices">
           ${this.optionsTemplate('?sort', currentOrder, options, urlParams)}
         </ul>

         ${this.renderSizeMode(currentSize, urlParams)}
        </div>
        `
  }

  feedContent(currentView, currentOrder, currentSize, urlParams) {
    let options = [ { key: 'newest-shared', label: 'newest' },
                    { key: 'oldest-shared', label: 'oldest' }];

    return `
        <div class="view-choices-wrapper">
          ${this.renderViewMode(currentView, urlParams)}

          <h4>Order</h4>

          <ul class="view-choices">
            ${this.optionsTemplate('?sort', currentOrder, options, urlParams)}
          </ul>
        ${this.renderSizeMode(currentSize, urlParams)}
        </div>
        `
  }

  defaultContent(currentView, currentOrder, currentSize, urlParams) {
    let options = [ { key: 'newest-created', label: 'newest' },
                    { key: 'oldest-created', label: 'oldest' }];

    return `
        <div class="view-choices-wrapper">
          ${this.renderViewMode(currentView, urlParams)}

          <h4>Order</h4>

          <ul class="view-choices">
            ${this.optionsTemplate('?sort', currentOrder, options, urlParams)}
          </ul>
        ${this.renderSizeMode(currentSize, urlParams)}
        </div>
        `
  }

  exploreContent(currentView, currentOrder, currentSize, urlParams) {
    let options = [ { key: 'most-popular', label: 'most popular' },
                    { key: 'less-popular', label: 'less popular' }];

    return `
        <div class="view-choices-wrapper">
          ${this.renderViewMode(currentView, urlParams)}

          <h4>Order</h4>

          <ul class="view-choices">
            ${this.optionsTemplate('?sort', currentOrder, options, urlParams)}
         </ul>
         ${this.renderSizeMode(currentSize, urlParams)}
       </div>
        `
  }

  loadContent() {
    const currentView = this.element.dataset.viewMode;
    const currentOrder = this.element.dataset.orderMode;
    const currentSize = this.element.dataset.sizeMode;

    let urlParams = new URLSearchParams(window.location.search).toString();

    urlParams = urlParams.replace(/&*view_mode=\w+&*/g, '');
    urlParams = urlParams.replace(/&*sort=[\w|-]+&*/g, '');
    urlParams = urlParams.replace(/&*size=[\w|-]+&*/g, '');

    if (urlParams.length > 0) {
      urlParams = `&${urlParams}`;3
    }

    const settingsContent = this.element.dataset.settingsContent || 'default';

    switch(settingsContent) {
    case 'default':
      return this.defaultContent(currentView, currentOrder, currentSize, urlParams);
      break;
    case 'feed':
      return this.feedContent(currentView, currentOrder, currentSize, urlParams);
      break;
    case 'explore':
      return this.exploreContent(currentView, currentOrder, currentSize, urlParams);
      break;
    case 'golinks':
      return this.goLinksContent(currentView, currentOrder, currentSize, urlParams);
      break;
    case 'lists':
      return this.listsContent(currentView, currentOrder, currentSize, urlParams);
      break;
    }
  }

  connect() {
    const $picker = this.context.element.querySelector('.view-picker');
    const currentView = this.element.dataset.viewMode;
    const currentOrder = this.element.dataset.orderMode;

    let urlParams = new URLSearchParams(window.location.search).toString();

    urlParams = urlParams.replace(/&*view_mode=\w+&*/g, '');
    urlParams = urlParams.replace(/&*sort=\w+&*/g, '');

    if (urlParams.length > 0) {
      urlParams = `&${urlParams}`;
    }

    const t = tippy($picker,
                    {
                      ...defaultTippyOptions(),
                      placement: 'bottom',
                      maxWidth: 700,
                      content: this.loadContent()
                    });
  }
}
