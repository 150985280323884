/* eslint no-console:0 */

require("@rails/ujs").start()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

import "@hotwired/turbo-rails";

// require("@rails/activestorage").start()
// require("trix")
// require("@rails/actiontext")

import { startCoreBehavior } from './core_behavior.js';
startCoreBehavior();

import * as ActionCable from '@rails/actioncable';
ActionCable.logger.enabled = true;
