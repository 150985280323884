import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let $element = $(this.element);

    if ($element.css('opacity') == 0) {
      this.element.style.gridRowEnd = "span 10";
      $element.animate({ opacity: 1 }, 100);
    }
  }
}
