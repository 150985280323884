import { Controller } from 'stimulus';
import tippy from 'tippy.js';
import { ajaxPut } from '../packs/utils.js';

export default class extends Controller {
  renderBackgrounds(e) {
    e.preventDefault();
    this.tippy = this.element._tippy || tippy(this.element, {
      trigger: 'click',
      interactive: true,
      distance: 25,
      arrow: true,
      placement: 'bottom'
    });

    this.content().then(function(content) {
      this.tippy.setContent(content);
      this.tippy.show();
    }.bind(this));
  }

  pickBackground(event) {
    let imgEl = event.target;
    let img = imgEl.src;
    let realImage = img.match(/thumbs\/(.*)/)[1];
    let url = `https://${Tefter.cdn}/wallpapers/${realImage}`;
    let $card = $('.account-card');
    let classes = Array.from($card.prop('classList'));

    $card.
      addClass('with-background').
      removeClass(classes.filter(c => c.match(/^theme-/)).join(' ')).
      addClass(`theme-${imgEl.dataset.theme}`).
      css('background-image', `url(${url})`);

    $('.change-background')[0]._tippy.hide();

    this.updateBackground(url);
  }

  updateBackground(background) {
    ajaxPut('/users/update_background', { background: background });
  }

  removeBackgroundElement() {
    if ($('.account-card').hasClass('with-background')) {
      return `<a
        href="#"
        class="remove-background"
        data-action="click->user-background#removeBackground">Remove background</a>`;
    } else {
      return '';
    }
  }

  removeBackground(e) {
    e.preventDefault();

    $('.account-card').
      removeClass('with-background').
      css('background-image', 'none');

    $('.change-background')[0]._tippy.hide();
    this.updateBackground(null);
  }

  content() {
    return this.availableImages().then(function(images) {
      return `
        <div data-controller="user-background"
             class="user-background wallpapers">
          ${this.removeBackgroundElement()}
          ${this.renderImageThumbs(images)}
          <span class="images-license">Images by <a href="https://unsplash.com/">unsplash</a></span>
        </div>`;
    }.bind(this));
  }

  renderImageThumbs(images) {
    return images.map(function(img) {
      return `
        <img
          data-theme="${img.theme}"
          data-action="click->user-background#pickBackground"
          src="/wallpapers/thumbs/${img.name}"/>
      `;
    }).join('\n');
  }

  availableImages() {
    if (this.backgrounds) {
      return Promise.resolve(this.backgrounds);
    } else {
      return $.get('/users/backgrounds').then(function(res) {
        return this.backgrounds = res.backgrounds;
      }.bind(this));
    }
  }
}
