import { debounce } from './vendor/debounce.js'

export default class Timeline {
  attach(options) {
    this.THRESHOLD = 300;
    this.debounce_time = 200;

    const userName = options.userName;
    this.context = options.userName;

    if (userName === '') {
      this.paginationUrl = 'pagination';
    } else {
      this.paginationUrl = userName + '/pagination';
    }

    this.isPaginating = false;
    this.currentPage = 1;
    this.older = 0;
    this.$paginationElem = $('.pagination');
    this.totalPages = parseInt(this.$paginationElem.attr('data-pagination-pages'));

    this.$paginationElem.hide();
    this.bindHandlers();
  }

  bindHandlers() {
    $(window).off('scroll');
    $(window).on('scroll', debounce(function () {
      this.handleScroll();
    }.bind(this), this.debounce_time));
  }

  $resources() { return $('#resources'); }

  paginationEndpoint(timestamp) {
    return `${this.paginationUrl}${this.historyEndpoint(timestamp)}`;
  }

  historyEndpoint(timestamp) {
    // For browser support see here https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#Browser_compatibility
    const urlParams = new URLSearchParams(window.location.search);

    let queryParams;

    if (this.context == 'popular') {
      queryParams = `?page=${this.currentPage}`;
    }
    else {
      queryParams = `?older=${timestamp}`;
    }

    // Keep bots flag
    if (urlParams.get('bot') != null) {
      queryParams += `&bot=${urlParams.get('bot')}`;
    }

    // Keep domain_id
    if (urlParams.get('domain_id') != null) {
      queryParams += `&domain_id=${urlParams.get('domain_id')}`;
    }

    // Keep d
    if (urlParams.get('d') != null) {
      queryParams += `&d=${urlParams.get('d')}`;
    }

    // Keep tags
    if (urlParams.get('tags') != null) {
      queryParams += `&tags=${urlParams.get('tags')}`;
    }

    // Keep query
    if (urlParams.get('q') != null) {
      queryParams += `&q=${urlParams.get('q')}`;
    }

    // Keep user filtering
    if (urlParams.get('u') != null) {
      queryParams += `&u=${urlParams.get('u')}`;
    }

    // Keep view_mode
    if (urlParams.get('view_mode') != null) {
      queryParams += `&view_mode=${urlParams.get('view_mode')}`;
    }

    // Keep order_mode
    if (urlParams.get('sort') != null) {
      queryParams += `&sort=${urlParams.get('sort')}`;
    }

    return queryParams;
  }

  oldestTimestamp() {
    let element = this.$resources().find('.card:last .created-at');

    if (element.length == 0) {
     element =  this.$resources().find('.condensed-card:last .created-at');
    }

    return Math.floor(
      Date.parse(
        JSON.parse(
          element.attr('date'))) / 1000);
  }

  handleScroll() {
    const $window = $(window);
    const $document = $(document);

    if (!this.isPaginating && this.currentPage < this.totalPages && $window.scrollTop() >
        $document.height() - $window.height() - this.THRESHOLD) {
      this.isPaginating = true;
      this.currentPage++;

      var oldest = this.oldestTimestamp();

      this.$paginationElem.show();

      $.ajax({
        url: this.paginationEndpoint(oldest)
      }).done(function (result) {
        var newIds = $.makeArray($(result).filter('.card').map(function(i, el) {
          return $(el).data('id')
        }));

        let url = this.historyEndpoint(oldest);
        history.pushState({turbo: true, url: url}, null, url);

        this.$resources().append(result);

        this.isPaginating = false;
      }.bind(this));
    }

    if (this.currentPage >= this.totalPages) {
      this.$paginationElem.hide();
    }
  }
}
