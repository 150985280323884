import { Controller } from "stimulus";

export default class extends Controller {
  toggleMenu(event) {
    this.element.classList.toggle('active')
  }

  toggleSearch(event) {
    event.preventDefault();
    $('.searchbar-mobile').toggleClass('active');

    if ($('.searchbar-mobile').hasClass('active')) {
      setTimeout(function() {
        $('#mobile-autocomplete input').focus();
      }, 200);
    }
  }

  toggleSidebar(event) {
    // Do not try to toggle Sidebar when a user tries to click an element
    // that belongs to the sidebar, eg. Feed or Search icon.
    if (event.srcElement.href != undefined) {
      return;
    }

    const element = $('.dashboard');
    const menuItems = $('.sidebar-menu a');
    const sidebar = $('.sidebar');
    let grid = element.css('grid-template-columns');

    if (grid == undefined) {
      return;
    }

    grid = grid.split(' ')[0];

    if (grid == '300px') {
      $(event.currentTarget).toggleClass('expanded');
      element.css('grid-template-columns', '50px 1fr');
      menuItems.css('font-size', '0px');
    }
    else if (grid == '50px') {
      $(event.currentTarget).toggleClass('expanded');
      menuItems.css('font-size', '16px');
      element.css('grid-template-columns', '300px 1fr');
    }
  }

  simpleClick(event) {
    let source = event.srcElement;

    if (source.localName == 'li') {
      event.preventDefault();
      event.stopPropagation();
      window.location = source.querySelector('a').href;
      return;
    }
  }

  toggleLists(event) {
    $('.toggle-lists').toggleClass('hidden');
    $(event.currentTarget).toggleClass('icon-right-open');
    $(event.currentTarget).toggleClass('icon-down-open');

  }

  toggleAliases(event) {
    $('.toggle-aliases').toggleClass('hidden');
    $(event.currentTarget).toggleClass('icon-right-open');
    $(event.currentTarget).toggleClass('icon-down-open');

    event.preventDefault();
    event.stopPropagation();
  }

  toggleBookmarks(event) {
    $('.toggle-bookmarks').toggleClass('hidden');
    $(event.currentTarget).toggleClass('icon-right-open');
    $(event.currentTarget).toggleClass('icon-down-open');

    event.preventDefault();
    event.stopPropagation();
  }

  toggleAccount(event) {
    $('.toggle-account').toggleClass('hidden');
    $(event.currentTarget).toggleClass('icon-right-open');
    $(event.currentTarget).toggleClass('icon-down-open');

    event.preventDefault();
    event.stopPropagation();
  }

  toggleSettings(event) {
    $('.toggle-settings').toggleClass('hidden');
    $(event.currentTarget).toggleClass('icon-right-open');
    $(event.currentTarget).toggleClass('icon-down-open');

    event.preventDefault();
    event.stopPropagation();
  }

  toggleExplore(event) {
    $('.toggle-explore').toggleClass('hidden');
    $(event.currentTarget).toggleClass('icon-right-open');
    $(event.currentTarget).toggleClass('icon-down-open');

    event.preventDefault();
    event.stopPropagation();
  }

  toggleTeams(event) {
    $('.toggle-teams').toggleClass('hidden');
    $(event.currentTarget).toggleClass('icon-right-open');
    $(event.currentTarget).toggleClass('icon-down-open');

    event.preventDefault();
    event.stopPropagation();
  }

  toggleOrganization(event) {
    $('.toggle-organization').toggleClass('hidden');
    $(event.currentTarget).toggleClass('icon-right-open');
    $(event.currentTarget).toggleClass('icon-down-open');

    event.preventDefault();
    event.stopPropagation();
  }
}
