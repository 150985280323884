import { Controller } from "stimulus"

export default class extends Controller {
  toggle(event) {
    event.preventDefault();
    event.srcElement.classList.toggle('active');

    const $selectedTags = $('.search-tag-selected');
    const $bookmarkFilters = $('.bookmarks-filters');

    $bookmarkFilters.toggleClass('active');
    $bookmarkFilters.toggleClass('hidden');
    $selectedTags.toggleClass('hidden');
  }
}
