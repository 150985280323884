export default class BookmarkForm {

  attach() {
    this.$uri = $('#resource_link_attributes_uri');
    this.$list_ids = $('#list_ids');
    this.$showHideNotes = $('#show-hide-notes');
    this.oldUri = this.$uri.val();

    // Do nothing if there's no feeback form
    if (this.$uri.length < 1) { return }

    this.URI_REGEX = /(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    this.bindHandlers();

    // Infer for old extensions pre-filling the form
    if (this.$uri.val()) {
      this.inferTitleAndTags(this.$uri.val());
      $('#haveit').hasClass('new') && this.checkHaveIt(this.$uri.val());
    }
  }

  bindHandlers() {
    this.$uri.on('keyup change', this.onChange.bind(this));
    this.$list_ids.on('updated', this.handleListUpdate.bind(this));

    $('.bookmark-notes-label').on('click', function(e) {
      $('.form-notes-group textarea').toggleClass('hidden');
      $('#show-hide-notes').toggleClass('hidden');
    });

    $('.bookmark-visibility-label').click(function(e) {
      const $bookmarkVisibility = $('#bookmark-visibility');
      const $visibilitySelect = $('#bookmark-visibility select');
      const $bookmarkLabelVisibility = $('#bookmark-label-visibility');
      const $showHideVisibility = $('#show-hide-visibility');

      e.preventDefault();
      $bookmarkVisibility.toggleClass('hidden');
      $visibilitySelect.focus();
      $bookmarkLabelVisibility.toggleClass('hidden');
      $('.form-visibility-group').toggleClass('shown');
      $showHideVisibility.toggleClass('hidden');
    });

    $('.bookmark-tags-label').click(function(e) {
      const $bookmarkTags = $('#resource-tags');
      const $bookmarkLabelTags = $('#bookmark-label-tags');
      const $showHideTags = $('#show-hide-tags');

      e.preventDefault();
      $bookmarkTags.toggleClass('hidden');
      $bookmarkTags.focus();
      $bookmarkLabelTags.toggleClass('hidden');
      $('.form-tag-group').toggleClass('shown');
      $showHideTags.toggleClass('hidden');
      window.tags.ref.select.focus();
    });

    $('.bookmark-orgs-label').click(function(e) {
      const $bookmarkOrgs = $('#aliases-orgs');
      const $bookmarkLabelOrgs = $('#bookmark-label-orgs');
      const $showHideOrgs = $('#show-hide-orgs');

      e.preventDefault();
      $bookmarkOrgs.toggleClass('hidden');
      $bookmarkOrgs.focus();
      $bookmarkLabelOrgs.toggleClass('hidden');
      $showHideOrgs.toggleClass('hidden');
    });

    $('.bookmark-title-label').click(function(e) {
      const $bookmarkTitle = $('#resource_title');
      const $bookmarkLabelTitle = $('#bookmark-label-title');
      const $showHideTitle = $('#show-hide-title');

      e.preventDefault();
      $bookmarkTitle.toggleClass('hidden');
      $bookmarkTitle.focus();
      $bookmarkLabelTitle.toggleClass('hidden');
      $('.form-title-group').toggleClass('shown');
      $showHideTitle.toggleClass('hidden');
    });

    let checkForInput = this.checkForInput

    // The lines below are executed on page load
    $('.form-address-group input').each(function() {
      checkForInput(this);
    });

    // The lines below (inside) are executed on change & keyup
    $('.form-address-group input').on('change keyup touchend', function() {
      checkForInput(this);
    });

    $('#resource_visibility').change(function() {
      const selectedElement = $('#resource_visibility option:selected');
      const visibilityChoice = selectedElement.text().toLowerCase();
      const visibilityDescs = $('#visibility-desc');

      visibilityDescs.children().each(function() {
        if ($(this).attr('id') === visibilityChoice) {
          $(this).removeClass('hidden');
        } else {
          $(this).addClass('hidden');
        }
      });
    });
  }

  checkForInput(element) {
    if ($(element).val().length > 0) {
      $('.form-title-group').addClass('active');
      $('.form-tag-group').addClass('active');
      $('.form-list-group').addClass('active');
      $('.form-visibility-group').addClass('active');
      $('.form-notes-group').addClass('active');
    }
  }

  handleListUpdate(_event, data) {
    const lists = data.value;
    const $visibility = $('#resource_visibility');

    if (lists.find((l) => !l.hidden) && $visibility.val() == 'hidden') {
      $visibility.val('visible');
      $visibility.trigger('change');
      $('#bookmark-label-visibility').text('visible');
    }
  }

  onChange(e) {
    const $target = e.currentTarget;
    const url = $target.value;

    $('#haveit').addClass('hidden');

    if (this.URI_REGEX.test(url)) {
      if (url == this.oldUri) {
        return;
      }

      this.inferTitleAndTags(url);

      if (e.type == 'change') {
        return;
      }

      this.checkHaveIt(url);
    }
  }

  uriChanged(url, oldUrl) {
    return oldUrl == '' || url.match(this.URI_REGEX) != null &&
      oldUrl.match(this.URI_REGEX) != null &&
      url.match(this.URI_REGEX)[0] != oldUrl.match(this.URI_REGEX)[0]
  }

  inferTitleAndTags(url) {
    const $title = $('#resource_title');
    const $titleLabel = $('#bookmark-label-title');
    const $notes = $('#resource_notes');
    const $tagNames = $('#tag_names');
    const $tagsLabel = $('#bookmark-label-tags');
    const forceUpdate = this.uriChanged(url, this.oldUri);
    const oldUri = this.oldUri;

    $.post('/bookmarks/infer_metadata', { url: url }, function(metadata) {
      if (metadata.title && ($title.val() == '' || $title.val() == url || forceUpdate)) {
        $title.val(metadata.title);
        $titleLabel.text(metadata.title);
      }

      if (metadata.notes && $notes.val() == '') {
        $notes.val(metadata.notes);
      }

      // If someone has already some tags do not try to load more
      // tags every time.
      if (window.tags.state.value.length > 0 && forceUpdate == false)
        return;

      // In case tags are empty but the bookmark is in edit mode
      // and the uri has not changed then do not try to fetch tags.
      if (oldUri != undefined && forceUpdate == false)
        return;

      if (metadata.tags) {
        metadata.tags.sort().map(function(t) {
          window.tags.addTag([{ label: t, value: t }]);
        });
      }

      if ($tagsLabel.text().trim() == '' && metadata.tags) {
        $tagsLabel.text(metadata.tags.sort().join(', '));
      }
    });
  }

  checkHaveIt(url) {
    const $haveIt = $('#haveit');

    const urlParams = new URLSearchParams(window.location.search);

    const listId = urlParams.get('list_id');

    $.post('/bookmarks/haveit', { url: url, organization_id: Tefter.organizationId }, function(response) {
      if (response.bookmark) {

        if (listId && response.bookmark.organization_id == Tefter.organizationId) {

          Turbo.visit(`/bookmarks/${response.bookmark.id}/edit?list_id=${listId}`, { action: 'replace' });
          return;
        }

        if (listId && response.bookmark.organizationId != Tefter.organizationId) {
          return;
        }

        $haveIt.removeClass('hidden');
        const $link = $haveIt.find('.edit-link');
        $link.prop('href', $link.prop('href').replace(':id', response.bookmark.id));
      }
    });
  }
}
