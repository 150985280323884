import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $('#pricing-choices').click(function() {
      $('.pricing-hobbyist').toggleClass('hidden');
      $('.pricing-pro').toggleClass('hidden');
    });
  }
}
