import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source"];

  connect() {
    const anchorValue = window.location.hash;

    if (anchorValue === "") { return }

    // Grab $element based on anchorValue and the first parent with
    // a class 'collapsable-card' and make them 'active'
    const $element = $(anchorValue);
    $element.addClass('active');
    $element.parents('.collapsable-card').addClass('active');
  }

  toggle(event) {
    event.preventDefault();
    event.srcElement.parentElement.classList.toggle('active');
  }
}
