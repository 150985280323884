export default class Onboarding {
  attach() {
    if (this.inProgress()) {
      this.timer = window.setInterval(this.refresh.bind(this), this.refreshInterval())
    }
  }

  inProgress() {
    return $('.onboarding-list').data('inProgress')
  }

  refresh() {
    if (!this.inProgress()) {
      window.clearInterval(this.timer);

      return;
    }

    $.getJSON('/onboarding.json', this.handleStatuses.bind(this));
  }

  handleStatuses(progress) {
    const statuses = progress.progress;

    // TODO: DRY
    if (statuses.first_follow) {
      this.complete('.first-follow');
    } else {
      this.setOngoing('.first-follow');
    }

    if (statuses.extension_installed) {
      this.complete('.extension-installed');
    } else {
      this.setOngoing('.extension-installed');
    }

    if (statuses.completed_import) {
      this.complete('.completed-import');
    } else {
      this.setOngoing('.completed-import');
    }

    if (statuses.has_avatar) {
      this.complete('.has-avatar');
    } else {
      this.setOngoing('.has-avatar');
    }

    if (statuses.first_bookmark) {
      this.complete('.first-bookmark');
    } else {
      this.setOngoing('.first-bookmark');
    }

    if (statuses.review_privacy) {
      this.complete('.privacy-review');
    } else {
      this.setOngoing('.privacy-review');
    }
  }

  complete(classname) {
    $(classname).find('.status').removeClass('hidden');
    $(classname).find('.action-button').addClass('hidden');
    $(classname).addClass('done');
  }

  setOngoing(classname) {
    $(classname).find('.status').addClass('hidden');
    $(classname).find('.action-button').removeClass('hidden');
  }

  refreshInterval() { return 5000; }
}
