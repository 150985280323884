import { Controller } from "stimulus";
import { prepareModal, deleteBookmarkCard, ajaxPost, defaultTippyOptions,
         tippyWithDynamicContent, hideAllBookmarksTippy, showModal } from '../packs/utils.js';


export default class extends Controller {
  static targets = ['url'];

  copy(event) {
    navigator.permissions.query({name: "clipboard-write"}).then(result => {
      if (result.state == "granted" || result.state == "prompt") {
        navigator.clipboard.writeText(this.urlTarget.text).
          then(function()
               {
                 event.target.classList.remove('btn-control');
                 event.target.classList.add('btn-edit');
                 event.target.text = 'Copied';
               },
               function()
               {
                 event.target.classList.remove('btn-control');
                 event.target.classList.add('btn-delete');
                 event.target.text = 'Fail';
               });
      }
    });
  }

  showPendingInvitations(event) {
    const orgId = event.srcElement.getAttribute('data-organization-id') ||
          event.srcElement.parentElement.getAttribute('data-organization-id');

    let path = `/invitations/pending_invitations`;

    if (orgId != '' && orgId != null) {
      path = `/orgs/${orgId}` + path;
    }

    let $document = $('body');
    const modalId = 'pending-invitations';

    // Hide all other tippy elements.
    hideAllBookmarksTippy();

    // Clear Turbolinks cache
    Turbo.clearCache();

    $.ajax({
      url: path
    }).done(function (result) {
      $document.append(result);

      showModal(modalId);
    });
  }
}
