import { Controller } from "stimulus";

export default class extends Controller {
  delete(event) {
    $(event.currentTarget).parents('.card').remove();
    this.decrementCount($('.resources-count'));
  }

  decrementCount($el) {
    const count = parseInt($el.text().replace('(', '').replace(')', ''), 10);

    $el.text(`(${count - 1})`);
  }
}
