export default class Subcscriptions  {
  attach() {
    if (typeof Stripe == 'undefined') {
      return
    }

    // Create a Stripe client.
    var stripe = Stripe(window.tefter_stripe_public_key);

      // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    var style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Create a token when the form is submitted.
    var form = document.getElementById('subscription-form');
     form.addEventListener('submit', function(e) {
      e.preventDefault();

      stripe.redirectToCheckout({
       // Make the id field from the Checkout Session creation API response
       // available to this file, so you can provide it as argument here
       // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
       sessionId: window.checkout_session_id
      });
   });
  }
}
