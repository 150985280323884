import { Controller } from "stimulus";
import { timeAgo } from '../packs/timeAgo.js';
import tippy from 'tippy.js';
import { showModal } from '../packs/utils.js';
import { hideAllBookmarksTippy, destroyAllBookmarksTippy,
         prepareModal, deleteBookmarkCard, ajaxPost,
         ajaxDelete, ajaxGet, defaultTippyOptions,
         checkAuthentication, tippyWithDynamicContent,
         webShare, closeModal } from '../packs/utils.js';

export default class extends Controller {
  static targets = ['date', 'notes', 'likeButton', 'share'];

  connect() {
    if (this.hasDateTarget == true ) {
      const bookmarkDate = JSON.parse(this.dateTarget.getAttribute('date'));
      const path = JSON.parse(this.dateTarget.getAttribute('path'));

      const content = `<span class="date"><a href=${path}>${timeAgo(bookmarkDate)}</a></span>`;
      this.dateTarget.innerHTML = content;
    }

    if (this.hasShareTarget == true) {
      if (!navigator.share) {
        this.shareTarget.parentElement.style.display = 'none';
      }
    }

    // Check for likeButton existence
    if (this.hasLikeButtonTarget == true) {
      if (!window.Tefter.cdn) {
        window.Tefter.initTefter();
      }

      const id = parseInt(this.element.dataset.id);
      const alreadyLiked = window.Tefter.likedBookmarkIds.includes(id);

      if (alreadyLiked) {
        const $element = $(this.likeButtonTarget);

        $element.removeClass('like');
        $element.addClass('unlike');
        $element.attr('title', 'Unlike this bookmark');
      }
    }

    if (this.hasNotesTarget == true) {
      const $element = this.notesTarget;

      if (typeof($element) == undefined ||
          $element.parentElement.querySelector('.tooltip-content') == null) {
        return;
      }

      const t = tippy($element,
                      {
                        ...defaultTippyOptions(),
                        trigger: 'mouseenter',
                        delay: $element.dataset['delay'] || 0,
                        placement: $element.dataset['placement'] || 'bottom',
                        content: $element.parentElement.querySelector('.tooltip-content').innerHTML
                      });
      if ($element.dataset['show']) {
        t.show();
      }
    }
  }

  share(event) {
    webShare(event);
  }

  copy(event) {
    const element = $(event.srcElement);
    const id = element.data('id');
    const parent = element.parent();
    const response = event.detail[0];

    if (response.status == 'unprocessable_entity') {
      this.tippy = $(event.srcElement)._tippy || tippy($(event.srcElement), {
        ...defaultTippyOptions(),
        distance: 25,
        placement: 'bottom-left'
      });

      let content = `Probably you have reached the maximum number of bookmarks that your plan
supports. Please <strong>upgrade</strong> your account or contact as at support@tefter.`

      this.tippy.setContent(content);
      this.tippy.show();
    }
    else {
      element
        .attr('href', event.detail[0].resource_path)
        .attr('target', '_blank')
        .css('font-size', '100%')
        .removeClass('icon-plus-circle')
        .addClass('bookmark-action')
        .removeAttr('data-remote')
        .removeAttr('data-method')
        .html('<span class=\'icon-check\'>added</span>');

      let id = event.srcElement.getAttribute('data-id');
      const $element = $("li[data-id='" + id + "']")[0];

      destroyAllBookmarksTippy();
    }
  }

  beforeSend(event) {
    checkAuthentication(event);
  }

  delete(event) {
    deleteBookmarkCard(event.srcElement);
  }

  /* Visually toggles favorite element from bookmarks actions. If the user is in
   * her favorites page, then the bookmark card is deleted also.
   */
  favorite(event) {
    const element = event.srcElement;

    element.classList.toggle('icon-heart-fill')
    element.classList.toggle('icon-heart')

    if (window.location.href.includes('lists/favorites')) {
      deleteBookmarkCard(event.srcElement);
    }
  }

  showReadingActions(event) {
    const {bookmarkId, listId, organizationId } = event.srcElement.dataset;
    const params = { list_id: listId };
    const path = `/bookmarks/${bookmarkId}/reading_actions?organization_id=${organizationId}`;

    tippyWithDynamicContent(event, path, params, {
      ...defaultTippyOptions(),
      trigger: 'mouseenter click',
      offset: '-20, 0',
      distance: 3,
      touch: true,
      delay: [20, 100],
      duration: [10, 10],
      placement: 'left-start',
      hideOnClick: true,
    }, { hideAllTippys: false, ignoreEventType: "click" });

  }

  like(event) {
    checkAuthentication(event);

    const element = $(event.srcElement);
    const like = element.hasClass('like');
    const linkId = element.parent().attr('id').split('-')[1];
    const likeUrl = '/likes';
    const likeCountElement = element.parent().find('.likes-count');
    const data = { link_id: linkId };

    if (like) {
      ajaxPost(likeUrl, data, function (result) {
        likeCountElement.text(function (_, c) {
          const likeCount = parseInt(c) || 0;
          return likeCount + 1;
        });
      })
    }
    else {
      ajaxDelete(likeUrl, data, function (result) {
        likeCountElement.text(function (_, c) {
          const likeCount = parseInt(c) || 0;
          return (likeCount - 1) || '';
        });
      });
    }

    element.toggleClass('like');
    element.toggleClass('unlike');
  }

  goto(event) {
    const element = $(event.srcElement);
    const path = element.data('gotoPath');

    if (element.data('integration') != null || !path) {
      return
    }

    element.attr('href', path);

    if (!element.is('a')) {
      window.open(path, '_blank');
    }
  }

  readableClick(event) {
    event.stopPropagation();
    event.preventDefault();

    if (window.history.length <= 2 && window.Tefter.user_signed_in === false) {
      window.location = window.Tefter.signInPath;
    } else if (window.history.length <= 2 && window.Tefter.user_signed_in === true) {
      window.location = '/';
    } else {
      window.history.back();
    }
  }

  actionsMouseLeave(event) {
    let element = event.srcElement._tippy;

    if (element && element.state.isEnabled == true && element.state.isShown == false) {
      event.srcElement._tippy.destroy();
    }
  }

  showActions(event) {
    const { bookmarkId, organizationId, actionName, controllerName } =
          event.srcElement.dataset;
    const listId = window.Tefter.listSlug || null;
    const params = { list_id: listId };
    const path = '/bookmarks/' + bookmarkId + '/actions?list_id=' + listId +
          '&actionName=' + actionName +
          '&controllerName=' + controllerName + '&organization_id=' + organizationId;
    let ellipsisButton = event.srcElement;

    tippyWithDynamicContent(event, path, params,{
      ...defaultTippyOptions(),
      distance: 8,
      offset: '-26, 5',
      touch: true,
      delay: [20, 100],
      duration: [10, 10],
      placement: 'top',
      hideOnClick: true,
      onShow(instance) {
        instance.set({trigger: 'click'});

        ellipsisButton.style.opacity = 1;
      },

      onHide(instance) {
        if (document.querySelectorAll('.bookmark-reading-actions').length > 0) {
          return false;
        }

        instance.set({trigger: 'click'});

        ellipsisButton.style.opacity = '';
      }
    });
  }

  // @todo: Remove jQuery
  actionsMouseEnter(event) {
    const id = event.srcElement.getAttribute('data-id');
    let $element = $("a.js-burger-button[data-bookmark-id='" + id + "']");

    if ($element.length > 0) {
      $element.css('opacity', 1);
    }
  }

  addToOrg(event) {
    prepareModal(event, 'bookmarks', 'org_modal', 'org-modal');
  }

  addToList(event) {
    prepareModal(event, 'bookmarks', 'list_modal', 'list-modal');
  }

  addToTag(event) {
    prepareModal(event, 'bookmarks', 'tag_modal', 'tag-modal');
  }

  showLikes(event) {
    prepareModal(event, 'bookmarks', 'likes', 'modal-like');
  }

  showUsers(event) {
    prepareModal(event, 'bookmarks', 'users', 'modal');
  }

  // @todo: Remove jQuery
  toggleReadState(event) {
    let $element = $(event.srcElement);
    let $parent = $(this.element);
    const selected = $element.hasClass('icon-circle');

    $parent.find('a').
      filter(a => $(a) != $element).
      removeClass('icon-circle').
      addClass('icon-circle-empty');

    if (selected) {
      $element.addClass('icon-circle-empty');
      $element.removeClass('icon-circle');
    } else {
      $element.removeClass('icon-circle-empty');
      $element.addClass('icon-circle');
    }

    if (window.location.href.includes('lists/finished') ||
        window.location.href.includes('lists/read_later')) {
      // Hide 2nd level tippy
      $parent.hide();

      deleteBookmarkCard(event.srcElement);
    }
  }

  followTag(event) {
    const tag = event.target.dataset.tag;
    let url = location.href;
    let domain = `https://${window.location.hostname}`;
    let visitedUrl = '';

    if (url.endsWith('#')) {
      url = url.slice(0, -1);
    }

    if (window.Tefter.environment != 'production') {
      domain = 'http://127.0.0.1:3000';
    }

    visitedUrl = `${domain}/filter?tag=${tag}&url=${url}`

    history.pushState({turbo: true, url: visitedUrl}, null, visitedUrl);
  }

  followDomain(event) {
    const d = event.target.dataset.d;
    let url = location.href;
    let domain = `https://${window.location.hostname}`;
    let visitedUrl = '';

    if (url.endsWith('#')) {
      url = url.slice(0, -1);
    }

    if (window.Tefter.environment != 'production') {
      domain = 'http://127.0.0.1:3000';
    }

    visitedUrl = `${domain}/filter?d=${d}&url=${url}`

    history.pushState({turbo: true, url: visitedUrl}, null, visitedUrl);
  }

  // Adds or removes a bookmark from an org.
  toggleBookmarkToOrg(event) {
    const bookmarkId = this.element.getAttribute('data-bookmark-id');
    const orgId = event.srcElement.getAttribute('id');
    const isChecked = event.srcElement.checked;
    const data = { organization_id: orgId, id: bookmarkId };
    let path = [`/bookmarks/${bookmarkId}/`];

    if (orgId != '' && orgId != null) {
      path.unshift(`/orgs/${orgId}`);
    }

    path.push( isChecked ? 'add_to_org' : 'remove_from_org' );

    ajaxPost(path.join(''), data);
  }

  // Adds or removes a bookmark from a list.
  toggleBookmarkToList(event) {
    const { bookmarkId, username: userName } = this.element.dataset;
    const listId = event.srcElement.getAttribute('id');
    const isChecked = event.srcElement.checked;
    const data = { list_id: listId, id: bookmarkId };
    let path = [`/bookmarks/${bookmarkId}/`];

    if (userName != '') {
      path.unshift(`/${userName}/lists/${listId}`);
    }

    path.push( isChecked ? 'add_to_list' : 'remove_from_list' );

    ajaxPost(path.join(''), data);
  }

  showReadable(event) {
    const bookmarkId = event.srcElement.getAttribute('data-bookmark-id');
    let path = `/bookmarks/${bookmarkId}/readable`;

    let $document = $('body');
    const modalId = `bookmark-readable-${bookmarkId}`;

    // Hide all other tippy elements.
    hideAllBookmarksTippy();

    // Clear Turbolinks cache
    Turbo.clearCache();

    $.ajax({
      url: path
    }).done(function (result) {
      $document.append(result);

      showModal(modalId);
    });
  }

  showSummary(event) {
    const bookmarkId = event.srcElement.getAttribute('data-bookmark-id');
    let path = `/bookmarks/${bookmarkId}/open_ai_summary`;

    let $document = $('body');
    const modalId = `bookmark-summary-${bookmarkId}`;

    // Hide all other tippy elements.
    hideAllBookmarksTippy();

    showModal('modal-loading');

    // Clear Turbolinks cache
    Turbo.clearCache();

    $.ajax({
      url: path
    }).done(function (result) {
      closeModal('modal-loading');

      $document.append(result);

      showModal(modalId);
    });

  }
}
