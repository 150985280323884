import tippy from 'tippy.js';

function loadAvatarContent() {
  const $headerAvatar = document.querySelector('#header-avatar');

  if ($headerAvatar == undefined) {
    return;
  }

  let headerAvatar = tippy($headerAvatar,
                           { interactive: true,
                             trigger: 'click',
                             theme: 'light',
                             offset: '0, 20',
                             arrow: true });

  if (typeof headerAvatar === 'undefined') {
    return;
  }

  headerAvatar.setContent(window.Tefter.headerAvatarContent);
}

function loadSidebarContent() {
  const $sidebarAvatar = document.querySelector('#sidebar-avatar');

  if ($sidebarAvatar == undefined) {
    return;
  }

  let sidebarAvatar = tippy($sidebarAvatar,
                            { interactive: true,
                              trigger: 'click',
                              theme: 'light',
                              offset: '0, 20',
                              arrow: true });

  if (sidebarAvatar == undefined) {
    return;
  }

  sidebarAvatar.setContent(window.Tefter.headerAvatarContent);
}

function loadHelpMenuContent() {
  const $helpMenu = document.querySelector('#help-menu');
  const $sidebarHelpMenu = document.querySelector('#sidebar-help-menu');

  if ($helpMenu == undefined) {
    return;
  }

  var helpMenu = tippy($helpMenu, { interactive: true,
                                    trigger: 'click',
                                    theme: 'light',
                                    offset: '0, 20',
                                    arrow: true })

  var sidebarHelpMenu = tippy($sidebarHelpMenu,
                              { interactive: true,
                                trigger: 'click',
                                theme: 'light',
                                offset: '0, 20',
                                arrow: true })

  if (helpMenu == undefined) {
    return;
  }

  const content = `<ul class="light-text help-menu"">
                  <li><a href="/feedback/new">Feedback</a></li>
                  <li><a href="/faq">FAQ</a></li>
                  <li><a href="https://guides.tefter.io/features">Features</a></li>
                  <li><a href="https://headwayapp.co/tefter-changelog">Changelog</a></li>
                  <li><a href="/pricing-plans">Pricing plans</a></li>
                  <li><a href="/privacy-policy">Privacy policy</a></li>
                  <li><a href="/terms">Terms of service</a></li>
                  </ul>`

  helpMenu.setContent(content);
  sidebarHelpMenu.setContent(content);
}

function resizeAllGridItems(){
  let grid = document.getElementsByClassName("grid")[0];
  if ( grid == undefined || grid.getAttribute('data-grid-fixed') == true)
    return;

  let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
  let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));

  let allItems = document.getElementsByClassName("card");
  for(let x = 0; x < allItems.length; x++){
    $(allItems[x]).animate({ opacity: 1 }, 100);
    resizeGridItem(allItems[x], rowHeight, rowGap);
  }
}

function resizeGridItem(item, rowHeight, rowGap){
  let rowSpan = Math.ceil((item.querySelector('.card-content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));

  item.style.gridRowEnd = "span " + rowSpan;
}

export function startCoreBehavior() {
  document.addEventListener('turbo:load', function() {
    // First of all, start with Tefter initialization. Basically with this step we
    // load some backend value that are not accessible through JavaScript space
    // directly.
    window.Tefter.initTefter();

    $.ajaxSetup({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
    });

    loadAvatarContent();

    loadSidebarContent();

    loadHelpMenuContent();

    resizeAllGridItems();
  });

  window.addEventListener('beforeinstallprompt', (event) => {
    window.TefterBeforeInstallPromptEvent = event;
  });

  // Clear Turbolinks cache before ajax requests
  document.addEventListener('ajax:before [data-remote]', () => {
    Turbo.clearCache();
  });
}
