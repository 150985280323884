import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["passwordField"];

  change(e) {
  }

  toggle(event) {
    if (this.passwordFieldTarget.type === 'password') {
      this.passwordFieldTarget.type = 'text';
    } else {
      this.passwordFieldTarget.type = 'password';
    }

    event.preventDefault();
  }
}
