import { Controller } from "stimulus";

import { ajaxPost } from '../packs/utils.js';

export default class extends Controller {
  static targets = ['haveit'];

  connect() {
    this.previousName = ':id';
  }


  keyUp(event) {
    const name = event.srcElement.value;
    const haveItElement = this.haveitTarget;
    const currentName = this.data.get('list-id');
    const organizationId = this.data.get('organization-id');
    const previousName = this.previousName;
    let element = this;

    if (name == currentName || event.key == "Enter") {
      return;
    }

    ajaxPost('/lists/haveit',
             { list_name: name, organization_id: organizationId }, function(response) {
               if (response.list) {
                 haveItElement.classList.remove('hidden');

                 const linkElements = haveItElement.querySelectorAll('.edit-link');
                 linkElements.forEach(function(linkElement) {
                   linkElement.href = linkElement.href.replace(previousName, response.list.id);
                 });

                 element.previousName = name;
               }
               else {
                 haveItElement.classList.add('hidden');
               }
             });
  }
}
