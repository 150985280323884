import { Controller } from "stimulus"
import tippy from 'tippy.js';
import { defaultTippyOptions } from '../packs/utils.js';

export default class extends Controller {
  connect() {
    const $element = this.element;

    if (typeof($element) == undefined ||
        $element.parentElement.querySelector('.tooltip-content') == null) {
      return;
    }

      const t = tippy($element,
          {
            ...defaultTippyOptions(),
            trigger: 'mouseenter',
            delay: $element.dataset['delay'] || 0,
            placement: $element.dataset['placement'] || 'bottom',
            content: $element.parentElement.querySelector('.tooltip-content').innerHTML
          });
      if ($element.dataset['show']) {
        t.show();
      }
  }
}
